body {
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./images/backgroundimage.jpg"); */
  background-color: #23395b;
  overflow-x: hidden;
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  body {
    padding-top: 150px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
