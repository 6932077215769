.links {
  color: #433a3f;
  font-weight: bold;
}
.links:hover {
  text-decoration: none;
  color: #406e8e;
  font-weight: bold;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #8ea8c3;
}

.header-icons {
  display: inline;
}

@media only screen and (max-width: 767px) {
  .header-icons {
    display: block;
  }
  .about-image {
    justify-content: center;
    display: flex;
  }
}

.card {
  margin: 0 auto;
}

.portfolio-link {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
  border-radius: 0.2em;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #406e8e;
  font-weight: bold;
}
.portfolio-link:hover {
  text-decoration: none;
  color: #161925;
}
